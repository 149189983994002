import { DATA_DATE_FORMAT } from '@/constants'
import { useStore } from 'vuex'
import { computed, ComputedRef, inject } from 'vue'
import format from 'date-fns/format'
import { HotelsListQueries, Location } from '@/store/modules/hotels'
import router from '@/router'
export interface UseSearchHotelType {
  handleSearchHotels: (page: number) => void
  handleSearchHotelsByLocation: (v: Location) => void
  handleGoHotels: () => void
  handleGoHotel: (id: number) => void
  handleSearch: () => void
  query: ComputedRef<HotelsListQueries>
}
export const useSearchHotels = (): UseSearchHotelType => {
  const locale: any = inject('locale')
  const store = useStore()
  const search = computed(() => store.state.form.search)
  const sorting = computed(() => store.state.hotels.hotelsSort)
  const keyword = computed(() => store.state.form.searchHotel)
  const query = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: HotelsListQueries | any = {
      check_in: format(search.value.startDate, DATA_DATE_FORMAT),
      check_out: format(search.value.endDate, DATA_DATE_FORMAT),
      city: search.value.city || 0,
      adults: search.value.adults,
      children: search.value.children,
      lowest_price: search.value.lowest_price,
      highest_price: search.value.highest_price,
      hotel_facility: search.value.hotel_facility,
      room_facility: search.value.room_facility,
      filter: search.value.filter,
      sorting: sorting.value,
      business_type: search.value.business_type
    }
    if (data.lowest_price === 0) {
      delete data.lowest_price
    }
    if (data.highest_price === 10000) {
      delete data.highest_price
    }
    return data
  })

  const addEventPush = () => {
    window.dataLayer.push({
      event: 'search',
      destination: query.value.city,
      date: `${query.value.check_in}~${query.value.check_out}`,
      guest: query.value.adults
    })
  }

  const handleSearchHotels = (page: number) => {
    addEventPush()
    store.dispatch('hotels/fetchHotelList', { ...query.value, page })
  }
  const handleSearchHotelsByLocation = ({ lat, lng }: Location) => {
    store.dispatch('hotels/fetchHotelListByLocation', { ...query.value, lat, lng, page: 1 })
  }
  const handleGoHotels = () => {
    router.push({ name: 'Hotels', params: { locale: locale.value }, query: { ...query.value } })
    handleSearchHotels(1)
  }
  const handleGoHotel = (id: number) => {
    delete query.value.city
    router.push({ name: 'Hotel', params: { id: id, locale: locale.value }, query: { ...query.value } })
    handleSearchHotels(1)
    store.dispatch('hotel/fetchHotel', {
      id,
      query: query.value
    })
  }

  const handleSearch = () => {
    if (keyword.value?.type === 'hotel') {
      const hotel = keyword.value
      handleGoHotel(hotel.key)
    } else {
      handleGoHotels()
    }
  }

  return { handleSearchHotels, handleSearchHotelsByLocation, handleGoHotels, handleGoHotel, handleSearch, query }
}
