import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/calendar/style";
import _Calendar from "vant/es/calendar";

var _components;

import "core-js/modules/es.function.name.js";
import { computed, defineComponent, inject, ref } from 'vue';
import { useStore } from 'vuex';
import format from 'date-fns/format';
import { zhTW } from 'date-fns/locale';
import { MOBILE_DATEWEEK_FORMAT } from '@/constants';
import startOfDay from 'date-fns/startOfDay';
import intervalToDuration from 'date-fns/intervalToDuration';
import { useSearchBar } from '@/hook/useSearchBar';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'DateRangeFilter',
  components: (_components = {}, _defineProperty(_components, _Calendar.name, _Calendar), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: ['dateRange', 'onClose'],
  setup: function setup(props) {
    var t = inject('t');
    var store = useStore();
    var dates = ref(props.dateRange);
    var dateRangeName = computed(function () {
      return [format(dates.value[0], MOBILE_DATEWEEK_FORMAT, {
        locale: zhTW
      }), format(dates.value[1], MOBILE_DATEWEEK_FORMAT, {
        locale: zhTW
      })];
    });

    var handleSelected = function handleSelected(values) {
      if (values.length === 2) {
        dates.value = values;
      }
    };

    var startDate = computed(function () {
      return dates.value[0];
    });
    var endDate = computed(function () {
      return dates.value[1];
    });
    var durationDays = computed(function () {
      return intervalToDuration({
        start: startDate.value,
        end: endDate.value
      }).days || 0;
    });

    var _useSearchBar = useSearchBar(),
        businessType = _useSearchBar.businessType,
        getEndDate = _useSearchBar.getEndDate;

    var handleSubmit = function handleSubmit() {
      store.dispatch('form/setSearchCondition', {
        field: 'startDate',
        value: startOfDay(startDate.value)
      });
      store.dispatch('form/setSearchCondition', {
        field: 'endDate',
        value: getEndDate({
          businessType: businessType.value,
          startDate: startDate.value,
          endDate: endDate.value
        })
      });
      props.onClose();
    };

    return {
      t: t,
      dates: dates,
      handleSelected: handleSelected,
      handleSubmit: handleSubmit,
      businessType: businessType,
      dateRangeName: dateRangeName,
      durationDays: durationDays
    };
  }
});