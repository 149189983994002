import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.number.constructor.js";
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import CityPopover from '@/components/share/CityPopover.vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  name: 'KeywordSearch',
  components: _defineProperty({
    SvgIcon: SvgIcon,
    CityPopover: CityPopover
  }, _Popover.name, _Popover),
  setup: function setup() {
    var store = useStore();
    var search = computed(function () {
      return store.state.form.search;
    });
    var locationInput = ref(null);
    var searchValue = ref('');
    var cities = computed(function () {
      return searchValue.value === '' ? store.state.options.cities : [];
    });
    var keywords = computed(function () {
      return store.state.options.keywords.filter(function (keyword) {
        var _keyword$name;

        return searchValue.value === '' ? false : (_keyword$name = keyword.name) === null || _keyword$name === void 0 ? void 0 : _keyword$name.toLowerCase().includes(searchValue.value.toLowerCase());
      });
    });
    var showFilter = computed(function () {
      return store.state.form.searchBar;
    });
    var keyword = computed(function () {
      return store.state.form.searchHotel;
    });

    var setKeyword = function setKeyword(hotel) {
      store.dispatch('form/setKeyword', hotel);
    };

    var cityText = computed(function () {
      var _cities$value$find;

      return (_cities$value$find = cities.value.find(function (city) {
        return city.city_id === Number(search.value.city);
      })) === null || _cities$value$find === void 0 ? void 0 : _cities$value$find.city_name;
    });

    var setShowFilter = function setShowFilter(type) {
      var _keywords$value;

      if ((_keywords$value = keywords.value) !== null && _keywords$value !== void 0 && _keywords$value[0]) {
        var _keywords$value2, _keywords$value3;

        setKeyword((_keywords$value2 = keywords.value) === null || _keywords$value2 === void 0 ? void 0 : _keywords$value2[0]);
        store.dispatch('form/setSearchBar', (_keywords$value3 = keywords.value) === null || _keywords$value3 === void 0 ? void 0 : _keywords$value3[0].type);
      } else {
        store.dispatch('form/setSearchBar', type);
      }
    };

    var _useSearchHotels = useSearchHotels(),
        handleGoHotels = _useSearchHotels.handleGoHotels;

    var setLocation = function setLocation(city) {
      store.dispatch('form/setSearchCondition', {
        field: 'city',
        value: city.city_id
      });
      setShowFilter('');
      searchValue.value = '';
      setKeyword(undefined);
    };

    var onClickOverlay = function onClickOverlay() {
      setShowFilter('');
      searchValue.value = '';
    };

    var resetSearch = function resetSearch() {
      store.dispatch('form/setSearchCondition', {
        field: 'city',
        value: 0
      });
    };

    return {
      locationInput: locationInput,
      searchValue: searchValue,
      setLocation: setLocation,
      onClickOverlay: onClickOverlay,
      resetSearch: resetSearch,
      showFilter: showFilter,
      setShowFilter: setShowFilter,
      search: search,
      cityText: cityText,
      cities: cities,
      keywords: keywords,
      handleGoHotels: handleGoHotels,
      setKeyword: setKeyword,
      keyword: keyword
    };
  }
});