import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44ab3ea8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "form-control"
};
var _hoisted_2 = ["placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  return _openBlock(), _createBlock(_component_van_cell_group, {
    class: "city-filter"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_cell, null, {
        value: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
            class: "icon",
            iconName: "search"
          }), _withDirectives(_createElementVNode("input", {
            class: "search-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.inputValue = $event;
            }),
            placeholder: _ctx.t('homepage.enter_a_destination_or_property')
          }, null, 8, _hoisted_2), [[_vModelText, _ctx.inputValue]]), _createVNode(_component_svg_icon, {
            class: "del-icon",
            iconName: "delete-text",
            onClick: _cache[1] || (_cache[1] = function () {
              return _ctx.setInputValue('');
            })
          })])];
        }),
        _: 1
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, function (city) {
        return _openBlock(), _createBlock(_component_van_cell, {
          class: "city-cell",
          key: city.city_id,
          onClick: function onClick() {
            return _ctx.setLocation(city.city_id);
          }
        }, {
          value: _withCtx(function () {
            return [_createVNode(_component_svg_icon, {
              class: "icon",
              iconName: "icon-24-location-outline"
            }), _createTextVNode(" " + _toDisplayString(city.city_name), 1)];
          }),
          _: 2
        }, 1032, ["onClick"]);
      }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywords, function (keyword) {
        return _openBlock(), _createBlock(_component_van_cell, {
          class: "city-cell",
          key: keyword.key,
          onClick: function onClick() {
            return _ctx.setKeyword(keyword);
          }
        }, {
          value: _withCtx(function () {
            return [_createVNode(_component_svg_icon, {
              class: "icon",
              iconName: keyword.type === 'city' ? 'icon-24-location-outline' : 'icon-24-hotel-outline'
            }, null, 8, ["iconName"]), _createTextVNode(" " + _toDisplayString(keyword.name), 1)];
          }),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))];
    }),
    _: 1
  });
}