import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/stepper/style";
import _Stepper from "vant/es/stepper";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.map.js";
import { defineComponent, computed, inject } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Selector from '@/components/share/Selector.vue';
export default defineComponent({
  name: 'PeoplePopover',
  components: (_components = {
    SvgIcon: SvgIcon,
    Selector: Selector
  }, _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, _Stepper.name, _Stepper), _components),
  props: ['slot', 'showPopover', 'placement', 'onClickOverlay'],
  setup: function setup() {
    var t = inject('t');
    var store = useStore();
    var search = computed(function () {
      return store.state.form.search;
    });
    var peopleGroups = computed(function () {
      return store.state.options.people;
    });
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });
    var childAges = computed(function () {
      return store.state.options.childAges.map(function (age) {
        return {
          label: age,
          value: age
        };
      });
    });

    var adjustSearchCondition = function adjustSearchCondition(field, value) {
      store.dispatch('form/setSearchCondition', {
        field: field,
        value: value
      });
    };

    var adjustChildAge = function adjustChildAge(age, index) {
      store.dispatch('form/setChildAge', {
        index: index,
        age: age
      });
    };

    return {
      t: t,
      search: search,
      adjustSearchCondition: adjustSearchCondition,
      peopleGroups: peopleGroups,
      childAges: childAges,
      adjustChildAge: adjustChildAge,
      businessType: businessType
    };
  }
});