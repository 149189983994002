import { MOBILE_DATEWEEK_FORMAT } from './../constants'
import { City } from '@/api/api'
import { SearchType } from '@/store/types/form'
import { computeCheckOutDate, computeDateForSelfHealth, notMoreThan90Days } from '@/utils'
import { addDays, differenceInDays, startOfDay } from 'date-fns'
import { computed, ref, Ref } from 'vue'
import { useStore } from 'vuex'
import { useSearchHotels } from './useSearchHotels'
import format from 'date-fns/format'
import { zhTW } from 'date-fns/locale'
export interface getEndDateType {
  businessType: number
  startDate: Date
  endDate: Date
}

export interface useSearchBarType {
  searchValue: Ref<string>
  setShowFilter: (v: string) => void
  search: Ref<SearchType>
  locationInput: Ref<string | null>
  dateRange: Ref<Date[]>
  durationDays: Ref<number>
  showFilter: Ref<string | undefined>
  businessType: Ref<number>
  hotelType: Ref<string[]>
  handleSearch: () => void
  setLocation: (v: City) => void
  onClickOverlay: () => void
  resetSearch: () => void
  getEndDate: (v: getEndDateType) => void
}

export const useSearchBar = (): useSearchBarType => {
  const businessType = computed(() => store.state.form.search.business_type)
  const store = useStore()
  const search = computed(() => store.state.form.search)
  const locationInput = ref(null)
  const searchValue = ref('')
  const hotelType = ref(['', 'homepage.normal_hotel', 'homepage.quarantine_hotel'])
  // const hotelType = ref(['', 'homepage.normal_hotel', 'homepage.quarantine_hotel', 'homepage.self_health_management'])
  const getEndDate = ({ businessType, startDate, endDate }: any) => {
    switch (businessType) {
      case 1:
        return notMoreThan90Days(startDate, endDate)
      case 2:
        return computeCheckOutDate(startDate, endDate)
      case 3:
        return computeDateForSelfHealth(startDate, endDate)
      default:
        return endDate
    }
  }
  const dateRange = computed({
    get: () => [search.value.startDate, search.value.endDate],
    set: (val: Date[]) => {
      const startDate = val[0]
      const endDate = val[1]
      store.dispatch('form/setSearchCondition', { field: 'startDate', value: startOfDay(startDate) })
      store.dispatch('form/setSearchCondition', {
        field: 'endDate',
        value: getEndDate({
          businessType: businessType.value,
          startDate,
          endDate
        })
      })
    }
  })
  const durationDays = computed(() => store.getters['form/getDuration'])
  const showFilter = computed(() => store.state.form.searchBar)

  const setShowFilter = (type: string) => {
    store.dispatch('form/setSearchBar', type)
  }

  const { handleSearch } = useSearchHotels()

  const setLocation = (city: City) => {
    store.dispatch('form/setSearchCondition', { field: 'city', value: city.city_id })
    setShowFilter('')
    searchValue.value = ''
  }

  const onClickOverlay = () => {
    setShowFilter('')
    searchValue.value = ''
  }

  const resetSearch = () => {
    store.dispatch('form/setSearchCondition', { field: 'city', value: 0 })
  }
  return {
    searchValue,
    setShowFilter,
    search,
    locationInput,
    dateRange,
    durationDays,
    showFilter,
    handleSearch,
    setLocation,
    onClickOverlay,
    resetSearch,
    businessType,
    hotelType,
    getEndDate
  }
}
