import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "input-div"
};
var _hoisted_2 = {
  key: 0,
  class: "selected-city-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_city_popover = _resolveComponent("city-popover");

  return _openBlock(), _createBlock(_component_city_popover, {
    showPopover: _ctx.showFilter === 'location',
    placement: "bottom-start",
    keywords: _ctx.keywords,
    cities: _ctx.cities,
    onSelectLocation: _ctx.setLocation,
    onClickOverlay: _ctx.onClickOverlay,
    onSetKeyword: _ctx.setKeyword
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "selector search-condition location",
        onClick: _cache[3] || (_cache[3] = _withModifiers(function () {
          _ctx.setShowFilter('location');

          _ctx.locationInput.focus();
        }, ["stop"]))
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "search"
      }), _createElementVNode("div", _hoisted_1, [!_ctx.searchValue && _ctx.showFilter !== 'location' ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.keyword && _ctx.keyword.name || _ctx.cityText), 1)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("input", {
        ref: "locationInput",
        class: "location-input",
        type: "text",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
          return _ctx.setShowFilter('location');
        }, ["stop"])),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.searchValue = $event;
        })
      }, null, 512), [[_vModelText, _ctx.searchValue]]), _ctx.searchValue ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "icon-div",
        onClick: _cache[2] || (_cache[2] = _withModifiers( //@ts-ignore
        function () {
          return _ctx.resetSearch && _ctx.resetSearch.apply(_ctx, arguments);
        }, ["stop"]))
      }, [_createVNode(_component_svg_icon, {
        class: "delIcon",
        iconName: "delettext"
      })])) : _createCommentVNode("", true)])])];
    }),
    _: 1
  }, 8, ["showPopover", "keywords", "cities", "onSelectLocation", "onClickOverlay", "onSetKeyword"]);
}