import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent, computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  name: 'CityFilter',
  components: (_components = {
    SvgIcon: SvgIcon
  }, _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, _CellGroup.name, _CellGroup), _components),
  props: ['hideFilter'],
  setup: function setup(props) {
    var store = useStore();

    var _useSearchHotels = useSearchHotels(),
        handleGoHotel = _useSearchHotels.handleGoHotel;

    var inputValue = ref('');
    var cities = computed(function () {
      return inputValue.value === '' ? store.state.options.cities : [];
    });
    var keywords = computed(function () {
      return store.state.options.keywords.filter(function (keyword) {
        var _keyword$name;

        return inputValue.value === '' ? false : (_keyword$name = keyword.name) === null || _keyword$name === void 0 ? void 0 : _keyword$name.toLowerCase().includes(inputValue.value.toLowerCase());
      });
    });

    var setLocation = function setLocation(value) {
      store.dispatch('form/setKeyword', '');
      store.dispatch('form/setSearchCondition', {
        field: 'city',
        value: value
      });
      props.hideFilter();
    };

    var setInputValue = function setInputValue() {
      inputValue.value = '';
    };

    var setKeyword = function setKeyword(hotel) {
      inputValue.value = hotel.name || '';
      store.dispatch('form/setKeyword', hotel);
      props.hideFilter();
    };

    var t = inject('t');
    return {
      t: t,
      cities: cities,
      keywords: keywords,
      inputValue: inputValue,
      setLocation: setLocation,
      setInputValue: setInputValue,
      setKeyword: setKeyword
    };
  }
});