import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import { defineComponent, reactive, computed, onMounted, onUnmounted, inject } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Popup from '@/components/share/Popup.vue';
import Page from '@/components/share/Page.vue';
import CityFilter from '@/components/mobile/CityFilter.vue';
import PeopleFilter from './PeopleFilter.vue';
import DateRangeFilter from './DateRangeFilter.vue';
import { useSearchHotels } from '@/hook/useSearchHotels';
import { debounce } from '@/utils/debounce';
import { useSearchBar } from '@/hook/useSearchBar';
import { addDays, format } from 'date-fns';
import { MOBILE_DATEWEEK_FORMAT, MOBILE_DATEWEEK_FORMAT_SHORT } from '@/constants';
import { zhTW } from 'date-fns/locale';

var getTitle = function getTitle(type) {
  switch (type) {
    case 'city':
      return 'homepage.please_enter_a_destination';

    case 'hotel':
      return '旅館類型';

    case 'dateRange':
      return 'homepage.select_the_date';

    case 'people':
      return 'homepage.please_select_the_number_of_people';

    default:
      return '';
  }
};

export default defineComponent({
  components: (_components = {
    SvgIcon: SvgIcon,
    Popup: Popup,
    Page: Page,
    CityFilter: CityFilter,
    PeopleFilter: PeopleFilter,
    DateRangeFilter: DateRangeFilter
  }, _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _components),
  props: {
    initExpandStatus: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    event: {
      type: Boolean
    },
    home: {
      type: Boolean
    },
    bannerImg: {
      type: String
    }
  },
  setup: function setup(props) {
    var store = useStore();
    var t = inject('t');
    var keyword = computed(function () {
      return store.state.form.searchHotel;
    });
    var search = computed(function () {
      return store.state.form.search;
    });
    var durationDays = computed(function () {
      return store.getters['form/getDuration'];
    });
    var cityText = computed(function () {
      var _store$state$options$;

      return ((_store$state$options$ = store.state.options.cities.find(function (city) {
        return city.city_id === Number(search.value.city); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      })) === null || _store$state$options$ === void 0 ? void 0 : _store$state$options$.city_name) || t('homepage.all_county_city');
    });
    var isExpand = computed(function () {
      return props.initExpandStatus ? props.initExpandStatus : store.state.form.isExpand;
    });
    var filter = reactive({
      isShow: false,
      type: '',
      title: '',
      position: 'right',
      height: '100%',
      round: false
    });

    var showFilter = function showFilter(content) {
      if (isExpand.value) {
        filter.isShow = true;
        filter.type = content; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        filter.title = t(getTitle(content));
        filter.position = content === 'hotel' ? 'bottom' : 'right';
        filter.height = content === 'hotel' ? '30%' : '100%';
        filter.round = content === 'hotel';
      }
    };

    var hideFilter = function hideFilter() {
      if (isExpand.value) {
        filter.isShow = false;
      }
    };

    var onExpandSearch = function onExpandSearch() {
      if (isExpand.value === false) {
        store.dispatch('form/setIsExpand', true);
      }
    };

    var _useSearchHotels = useSearchHotels(),
        handleSearch = _useSearchHotels.handleSearch;

    var handelMobileSearch = function handelMobileSearch() {
      handleSearch();
      store.dispatch('form/setIsExpand', false);
    };

    var lastPosition = window.pageYOffset;

    var handleScroll = function handleScroll() {
      if (isExpand.value) {
        var isScrolling = function isScrolling() {
          return Math.abs(lastPosition - window.pageYOffset) > 500;
        };

        if (isScrolling()) {
          lastPosition = window.pageYOffset;
          store.dispatch('form/setIsExpand', false);
        }
      } else {
        lastPosition = window.pageYOffset;
      }
    };

    onMounted(function () {
      window.addEventListener('scroll', debounce(handleScroll, 50));
    });
    onUnmounted(function () {
      window.removeEventListener('scroll', handleScroll);
    });

    var _useSearchBar = useSearchBar(),
        businessType = _useSearchBar.businessType,
        hotelType = _useSearchBar.hotelType,
        dateRange = _useSearchBar.dateRange;

    var dateRangeLong = computed(function () {
      return [format(dateRange.value[0], MOBILE_DATEWEEK_FORMAT, {
        locale: zhTW
      }), format(dateRange.value[1], MOBILE_DATEWEEK_FORMAT, {
        locale: zhTW
      })];
    });
    var dateRangeShort = computed(function () {
      return [format(dateRange.value[0], MOBILE_DATEWEEK_FORMAT_SHORT, {
        locale: zhTW
      }), format(dateRange.value[1], MOBILE_DATEWEEK_FORMAT_SHORT, {
        locale: zhTW
      })];
    });
    var hotelGroups = computed(function () {
      return [{
        title: 'homepage.normal_hotel',
        type: 1
      }, {
        title: 'homepage.quarantine_hotel',
        type: 2
      } // {
      //   title: 'homepage.self_health_management',
      //   type: 3
      // }
      ];
    });

    var adjustSearchCondition = function adjustSearchCondition(field, value) {
      store.dispatch('form/setSearchCondition', {
        field: field,
        value: value
      });
      hideFilter();
      dateRange.value = [search.value.startDate, addDays(search.value.startDate, 1)];

      if (field === 'business_type') {
        store.dispatch('options/setGuestNumber', value);
        store.dispatch('form/setSearchCondition', {
          field: 'adults',
          value: value === 1 ? 2 : 1
        });
      }

      setTimeout(function () {
        showFilter('dateRange');
      }, 500);
    };

    return {
      t: t,
      onExpandSearch: onExpandSearch,
      isExpand: isExpand,
      filter: filter,
      showFilter: showFilter,
      hideFilter: hideFilter,
      search: search,
      dateRange: dateRange,
      durationDays: durationDays,
      cityText: cityText,
      handelMobileSearch: handelMobileSearch,
      keyword: keyword,
      businessType: businessType,
      hotelType: hotelType,
      hotelGroups: hotelGroups,
      adjustSearchCondition: adjustSearchCondition,
      dateRangeLong: dateRangeLong,
      dateRangeShort: dateRangeShort
    };
  }
});