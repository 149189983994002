import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/stepper/style";
import _Stepper from "vant/es/stepper";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { defineComponent, computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Popup from '@/components/share/Popup.vue';
import NavBar from '@/components/share/NavBar.vue';
import ListMenu from '@/components/share/ListMenu.vue';
import ListItem from '@/components/share/ListItem.vue';
export default defineComponent({
  name: 'PeopleFilter',
  components: (_components = {
    SvgIcon: SvgIcon,
    Popup: Popup,
    NavBar: NavBar,
    ListMenu: ListMenu,
    ListItem: ListItem
  }, _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, _Stepper.name, _Stepper), _components),
  props: ['onClose'],
  setup: function setup() {
    var t = inject('t');
    var store = useStore();
    var childIndex = ref();
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });

    var adjustSearchCondition = function adjustSearchCondition(field, value) {
      store.dispatch('form/setSearchCondition', {
        field: field,
        value: value
      });
    };

    var search = computed(function () {
      return store.state.form.search;
    });
    var peopleGroups = computed(function () {
      return store.state.options.people;
    });
    var childAges = computed(function () {
      return store.state.options.childAges;
    });

    var setChildAge = function setChildAge(idx) {
      childIndex.value = idx;
    };

    var closeAgeModal = function closeAgeModal() {
      childIndex.value = undefined;
    };

    var adjustChildAge = function adjustChildAge(age) {
      store.dispatch('form/setChildAge', {
        index: childIndex.value,
        age: age
      });
      setTimeout(function () {
        closeAgeModal();
      }, 0);
    };

    return {
      t: t,
      adjustSearchCondition: adjustSearchCondition,
      search: search,
      peopleGroups: peopleGroups,
      setChildAge: setChildAge,
      childIndex: childIndex,
      closeAgeModal: closeAgeModal,
      adjustChildAge: adjustChildAge,
      childAges: childAges,
      businessType: businessType
    };
  }
});