import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3790d82a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "city-popover"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_van_popover = _resolveComponent("van-popover");

  return _openBlock(), _createBlock(_component_van_popover, {
    class: "city-popover__wrapper",
    show: _ctx.showPopover,
    placement: _ctx.placement,
    overlay: "",
    "show-arrow": false,
    onClickOverlay: _ctx.onClickOverlay,
    "overlay-class": "city-overlay"
  }, {
    reference: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_cell_group, null, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, function (city) {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: city.city_id,
              onClick: function onClick() {
                return _ctx.onSelectLocation(city);
              }
            }, {
              value: _withCtx(function () {
                return [_createVNode(_component_svg_icon, {
                  class: "icon",
                  iconName: "icon-24-location-outline"
                }), _createTextVNode(" " + _toDisplayString(city.city_name), 1)];
              }),
              _: 2
            }, 1032, ["onClick"]);
          }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywords, function (keyword) {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: keyword.key,
              onClick: function onClick() {
                return _ctx.handleClickHotel() && _ctx.emit('setKeyword', keyword);
              }
            }, {
              value: _withCtx(function () {
                return [_createVNode(_component_svg_icon, {
                  class: "icon",
                  iconName: keyword.type === 'city' ? 'icon-24-location-outline' : 'icon-24-hotel-outline'
                }, null, 8, ["iconName"]), _createTextVNode(" " + _toDisplayString(keyword.name), 1)];
              }),
              _: 2
            }, 1032, ["onClick"]);
          }), 128))];
        }),
        _: 1
      })])];
    }),
    _: 3
  }, 8, ["show", "placement", "onClickOverlay"]);
}