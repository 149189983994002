import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.number.constructor.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3bb334d2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "people-popover"
};
var _hoisted_2 = {
  class: "counter"
};
var _hoisted_3 = {
  class: "counts"
};
var _hoisted_4 = {
  key: 0,
  class: "line"
};
var _hoisted_5 = {
  key: 1,
  class: "age-title"
};
var _hoisted_6 = {
  key: 2
};
var _hoisted_7 = {
  class: "selector"
};
var _hoisted_8 = {
  class: "warning"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_i18n_t = _resolveComponent("i18n-t");

  var _component_van_stepper = _resolveComponent("van-stepper");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_selector = _resolveComponent("selector");

  var _component_van_popover = _resolveComponent("van-popover");

  return _openBlock(), _createBlock(_component_van_popover, {
    class: "people-popover__wrapper",
    show: _ctx.showPopover,
    placement: _ctx.placement,
    overlay: "",
    "show-arrow": false,
    onClickOverlay: _ctx.onClickOverlay,
    "overlay-class": "people-overlay"
  }, {
    reference: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_cell_group, {
        class: "options"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.peopleGroups, function (people) {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: people.value
            }, {
              title: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_i18n_t, {
                  tag: "div",
                  class: "text",
                  keypath: "homepage.".concat(people.n_lang_key)
                }, {
                  n: _withCtx(function () {
                    return [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.search[people.value]), 1)];
                  }),
                  _: 2
                }, 1032, ["keypath"]), _createVNode(_component_van_stepper, {
                  "async-change": "",
                  value: _ctx.search[people.value],
                  "default-value": _ctx.search[people.value],
                  onChange: function onChange(value) {
                    return _ctx.adjustSearchCondition(people.value, value);
                  },
                  min: people.min,
                  max: people.max,
                  theme: "round",
                  "button-size": "36",
                  "disable-input": "",
                  "show-input": false
                }, null, 8, ["value", "default-value", "onChange", "min", "max"])])];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      }), Number(_ctx.search.children) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true), Number(_ctx.search.children) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t('homepage.please_select_the_age_of_the_child')), 1)) : _createCommentVNode("", true), Number(_ctx.search.children) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.search.childrenAge, function (age, index) {
        return _openBlock(), _createBlock(_component_selector, {
          key: "age_".concat(index),
          options: _ctx.childAges,
          selectedValue: age,
          onSelect: function onSelect(age) {
            return _ctx.adjustChildAge(age, index);
          },
          labelOptions: function labelOptions(label) {
            return _ctx.t('homepage.n_years_old', {
              n: label === 0 ? '< 1' : label
            });
          },
          class: "selector__wrapper"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_7, [_createTextVNode(_toDisplayString(_ctx.t('homepage.n_years_old', {
              n: age === 0 ? '< 1' : age
            })) + " ", 1), _createVNode(_component_svg_icon, {
              class: "icon",
              iconName: "icon_24_arrow_caret_down"
            })])];
          }),
          _: 2
        }, 1032, ["options", "selectedValue", "onSelect", "labelOptions"]);
      }), 128))])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_8, [_createVNode(_component_svg_icon, {
        class: "info-icon",
        iconName: "warning"
      }), _createElementVNode("div", null, _toDisplayString(_ctx.t('homepage.the_quarantine_protocol_accepts_only_one_person_in_one_room_in_principle_n_please_contact_the_customer_service_for_the_extra_person_rules_and_the_person_added_price_if_needed')), 1)], 512), [[_vShow, _ctx.businessType !== 1]]), _createElementVNode("button", {
        class: "submit-btn",
        onClick: _cache[0] || (_cache[0] = //@ts-ignore
        function () {
          return _ctx.onClickOverlay && _ctx.onClickOverlay.apply(_ctx, arguments);
        })
      }, _toDisplayString(_ctx.t('homepage.done')), 1)])];
    }),
    _: 3
  }, 8, ["show", "placement", "onClickOverlay"]);
}