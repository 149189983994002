import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6aa9e983"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "warning"
};
var _hoisted_2 = {
  key: 0,
  class: "children-title"
};
var _hoisted_3 = {
  class: "van-cell__value"
};
var _hoisted_4 = {
  class: "age-popup"
};
var _hoisted_5 = {
  class: "icon-place"
};
var _hoisted_6 = {
  class: "button-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_stepper = _resolveComponent("van-stepper");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_nav_bar = _resolveComponent("nav-bar");

  var _component_list_item = _resolveComponent("list-item");

  var _component_list_menu = _resolveComponent("list-menu");

  var _component_popup = _resolveComponent("popup");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_cell_group, {
    class: "options"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.peopleGroups, function (people) {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: people.value
        }, {
          title: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(_ctx.t("homepage.".concat(people.lang_key))), 1)];
          }),
          value: _withCtx(function () {
            return [_createVNode(_component_van_stepper, {
              "async-change": "",
              value: _ctx.search[people.value],
              "default-value": _ctx.search[people.value],
              onChange: function onChange(value) {
                return _ctx.adjustSearchCondition(people.value, value);
              },
              min: people.min,
              max: people.max,
              theme: "round",
              "button-size": "24",
              "disable-input": ""
            }, null, 8, ["value", "default-value", "onChange", "min", "max"])];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }), _withDirectives(_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
    class: "info-icon",
    iconName: "warning"
  }), _createElementVNode("div", null, _toDisplayString(_ctx.t('homepage.the_quarantine_protocol_accepts_only_one_person_in_one_room_in_principle_n_please_contact_the_customer_service_for_the_extra_person_rules_and_the_person_added_price_if_needed')), 1)], 512), [[_vShow, _ctx.businessType !== 1]]), _ctx.search.childrenAge.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t('homepage.please_select_the_age_of_the_child')), 1)) : _createCommentVNode("", true), _createVNode(_component_van_cell_group, {
    class: "children"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.search.childrenAge, function (age, index) {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: index,
          onClick: function onClick() {
            return _ctx.setChildAge(index);
          }
        }, {
          title: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(_ctx.t('homepage.children')) + " " + _toDisplayString(index + 1), 1)];
          }),
          value: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(_ctx.t('homepage.n_years_old', {
              n: _ctx.search.childrenAge[index] === 0 ? '< 1' : _ctx.search.childrenAge[index]
            })), 1), _createVNode(_component_svg_icon, {
              class: "right-icon",
              iconName: "icon_24_arrow_right"
            })])];
          }),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))];
    }),
    _: 1
  }), _createVNode(_component_popup, {
    class: "popup",
    show: Boolean(_ctx.childIndex !== undefined),
    position: "bottom",
    round: ""
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_nav_bar, {
        onClose: _ctx.closeAgeModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.t('homepage.child_age')), 1)];
        }),
        _: 1
      }, 8, ["onClose"]), _createVNode(_component_list_menu, {
        class: "list-menu"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childAges, function (age) {
            return _openBlock(), _createBlock(_component_list_item, {
              key: age,
              onClick: function onClick() {
                return _ctx.adjustChildAge(age);
              }
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_5, [_ctx.search.childrenAge[_ctx.childIndex] === age ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  class: "checked-icon",
                  iconName: "icon_24_dropdownmenu_check"
                })) : _createCommentVNode("", true)]), _createTextVNode(" " + _toDisplayString(age === 0 ? _ctx.t('homepage.n_years_old', {
                  n: '< 1'
                }) : age), 1)];
              }),
              _: 2
            }, 1032, ["onClick"]);
          }), 128))];
        }),
        _: 1
      })])];
    }),
    _: 1
  }, 8, ["show"]), _createElementVNode("div", _hoisted_6, [_createElementVNode("button", {
    class: "confirm-button",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.onClose && _ctx.onClose.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.t('homepage.done')), 1)])], 64);
}