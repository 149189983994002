import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-467ac103"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content maxWidth"
};
var _hoisted_2 = {
  class: "search-icon"
};
var _hoisted_3 = {
  key: 0,
  class: "noExpand-text"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "text-field"
};
var _hoisted_6 = {
  class: "icon"
};
var _hoisted_7 = {
  class: "text"
};
var _hoisted_8 = {
  class: "shrink-section"
};
var _hoisted_9 = {
  class: "text-field"
};
var _hoisted_10 = {
  class: "icon"
};
var _hoisted_11 = {
  class: "text"
};
var _hoisted_12 = {
  class: "date"
};
var _hoisted_13 = {
  key: 0
};
var _hoisted_14 = {
  key: 1
};
var _hoisted_15 = {
  class: "duration"
};
var _hoisted_16 = {
  class: "text-field"
};
var _hoisted_17 = {
  class: "icon"
};
var _hoisted_18 = {
  class: "text"
};
var _hoisted_19 = {
  class: "user adult"
};
var _hoisted_20 = {
  key: 0,
  class: "user child"
};
var _hoisted_21 = {
  class: "user room"
};
var _hoisted_22 = {
  class: "search-btn"
};
var _hoisted_23 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _normalizeClass2;

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_i18n_t = _resolveComponent("i18n-t");

  var _component_city_filter = _resolveComponent("city-filter");

  var _component_date_range_filter = _resolveComponent("date-range-filter");

  var _component_people_filter = _resolveComponent("people-filter");

  var _component_page = _resolveComponent("page");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_popup = _resolveComponent("popup");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("section", {
    class: _normalizeClass((_normalizeClass2 = {
      'mobile-search': true
    }, _defineProperty(_normalizeClass2, _ctx.className, Boolean(_ctx.className)), _defineProperty(_normalizeClass2, "shrink", !_ctx.isExpand), _defineProperty(_normalizeClass2, 'home-mobile-bg', _ctx.home), _defineProperty(_normalizeClass2, 'travel-page', !_ctx.bannerImg && _ctx.businessType === 1), _normalizeClass2)),
    onClick: _cache[4] || (_cache[4] = //@ts-ignore
    function () {
      return _ctx.onExpandSearch && _ctx.onExpandSearch.apply(_ctx, arguments);
    }),
    style: _normalizeStyle({
      '--bannerImg': "url(".concat(_ctx.bannerImg, ")")
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "search"
  })]), !_ctx.isExpand ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("b", null, [_createTextVNode(_toDisplayString(_ctx.keyword && _ctx.keyword.name || _ctx.cityText) + " " + _toDisplayString(_ctx.dateRangeShort[0]) + " - " + _toDisplayString(_ctx.dateRangeShort[1]) + " " + _toDisplayString(_ctx.search.adults) + " 人 ", 1), _ctx.search.children !== 0 ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.t('homepage.n_children', {
    n: _ctx.search.children
  })), 1)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(_ctx.t('order.n_room', {
    n: _ctx.search.room
  })), 1)])])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["search-text", {
      noExpand: !_ctx.isExpand
    }])
  }, [_createElementVNode("div", {
    class: "form-group",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.showFilter('city');
    })
  }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("i", _hoisted_6, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "search"
  })]), _createElementVNode("div", _hoisted_7, [_createElementVNode("b", null, _toDisplayString(_ctx.keyword && _ctx.keyword.name || _ctx.cityText), 1)])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
    class: "form-group",
    onClick: _cache[1] || (_cache[1] = function () {
      return _ctx.showFilter('dateRange');
    })
  }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("i", _hoisted_10, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "search-calendar"
  })]), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, [_ctx.isExpand ? (_openBlock(), _createElementBlock("b", _hoisted_13, _toDisplayString(_ctx.dateRangeLong[0]) + " - " + _toDisplayString(_ctx.dateRangeLong[1]), 1)) : (_openBlock(), _createElementBlock("b", _hoisted_14, _toDisplayString(_ctx.dateRangeShort[0]) + " - " + _toDisplayString(_ctx.dateRangeShort[1]), 1))]), _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('homepage.n_night', {
    n: _ctx.durationDays
  })), 1)])])]), _createElementVNode("div", {
    class: "form-group",
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.showFilter('people');
    })
  }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("i", _hoisted_17, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "search-user"
  })]), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", _hoisted_19, [_createVNode(_component_i18n_t, {
    keypath: "homepage.n_people"
  }, {
    n: _withCtx(function () {
      return [_createElementVNode("b", null, _toDisplayString(!_ctx.isExpand ? Number(_ctx.search.adults) + Number(_ctx.search.children) : _ctx.search.adults), 1)];
    }),
    _: 1
  })]), _ctx.search.children !== 0 ? (_openBlock(), _createElementBlock("span", _hoisted_20, [_createVNode(_component_i18n_t, {
    keypath: "homepage.n_children"
  }, {
    n: _withCtx(function () {
      return [_createElementVNode("b", null, _toDisplayString(_ctx.search.children), 1)];
    }),
    _: 1
  })])) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('order.n_room', {
    n: _ctx.search.room
  })), 1)])])])]), _createElementVNode("div", _hoisted_22, [_createElementVNode("button", {
    class: "main-btn",
    onClick: _cache[3] || (_cache[3] = _withModifiers(function ($event) {
      return _ctx.handelMobileSearch();
    }, ["stop"]))
  }, _toDisplayString(_ctx.t('homepage.search')), 1)])], 2))])], 6), _createVNode(_component_popup, {
    show: _ctx.filter.isShow,
    position: _ctx.filter.position,
    style: _normalizeStyle({
      height: _ctx.filter.height
    }),
    clickOverlay: _ctx.hideFilter,
    round: _ctx.filter.round
  }, {
    default: _withCtx(function () {
      var _normalizeClass3;

      return [_ctx.filter.type !== 'hotel' ? (_openBlock(), _createBlock(_component_page, {
        key: 0,
        class: _normalizeClass((_normalizeClass3 = {}, _defineProperty(_normalizeClass3, 'filter-content-popup', true), _defineProperty(_normalizeClass3, 'gray-bg', _ctx.filter.type === 'people'), _normalizeClass3)),
        onClose: _ctx.hideFilter,
        title: _ctx.filter.title
      }, {
        default: _withCtx(function () {
          return [_ctx.filter.type === 'city' ? (_openBlock(), _createBlock(_component_city_filter, {
            key: 0,
            hideFilter: _ctx.hideFilter
          }, null, 8, ["hideFilter"])) : _createCommentVNode("", true), _ctx.filter.type === 'dateRange' ? (_openBlock(), _createBlock(_component_date_range_filter, {
            key: 1,
            dateRange: [_ctx.search.startDate, _ctx.search.endDate],
            onClose: _ctx.hideFilter
          }, null, 8, ["dateRange", "onClose"])) : _ctx.filter.type === 'people' ? (_openBlock(), _createBlock(_component_people_filter, {
            key: 2,
            onClose: _ctx.hideFilter
          }, null, 8, ["onClose"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["class", "onClose", "title"])) : (_openBlock(), _createBlock(_component_page, {
        key: 1,
        class: "hotel-filter",
        onClose: _ctx.hideFilter,
        title: _ctx.filter.title
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_cell_group, {
            class: "options"
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelGroups, function (hotel, index) {
                return _openBlock(), _createBlock(_component_van_cell, {
                  key: index
                }, {
                  title: _withCtx(function () {
                    return [_createElementVNode("div", {
                      class: "counter",
                      onClick: function onClick(value) {
                        return _ctx.adjustSearchCondition('business_type', hotel.type);
                      }
                    }, [_createElementVNode("div", null, _toDisplayString(_ctx.t(hotel.title)), 1), _ctx.businessType === hotel.type ? (_openBlock(), _createBlock(_component_svg_icon, {
                      key: 0,
                      class: "svg-icon",
                      iconName: "check"
                    })) : _createCommentVNode("", true)], 8, _hoisted_23)];
                  }),
                  _: 2
                }, 1024);
              }), 128))];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["onClose", "title"]))];
    }),
    _: 1
  }, 8, ["show", "position", "style", "clickOverlay", "round"])], 64);
}