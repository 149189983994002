import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'CityPopover',
  components: (_components = {}, _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: ['slot', 'showPopover', 'placement', 'onClickOverlay', 'cities', 'keywords', 'onSelectLocation'],
  emit: ['setKeyword'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var handleClickHotel = function handleClickHotel() {
      props.onClickOverlay();
      return true;
    };

    return {
      handleClickHotel: handleClickHotel,
      emit: emit
    };
  }
});